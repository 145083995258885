import { lazy } from "react";
import { Navigate, Outlet } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

const DashboardLayout = lazy(() => import('../Modules/Dashboard/Dashboard'));
const HomeLayout = lazy(() => import('../Modules/Dashboard/User/Home/Home'));
const ChatLayout = lazy(() => import('../Modules/Dashboard/User/Chat/Chat'));
const LoginLayout = lazy(() => import('../Modules/Dashboard/Auth/LoginScreen/LoginScreen'));
const SubscriptionsLayout = lazy(() => import('../Modules/Dashboard/Admin/SubscriptionList/SubscriptionList'));
const UsersListLayout = lazy(() => import('../Modules/Dashboard/Admin/UsersListScreen/UsersListScreen'));
const PackageListLayout = lazy(() => import('../Modules/Dashboard/Admin/PackageManagement/PackageList'));
const AddPackageLayout = lazy(() => import('../Modules/Dashboard/Admin/PackageManagement/AddPackage/AddPackage'));
const SettingsLayout = lazy(() => import('../Modules/Dashboard/User/Settings/Settings'));
const ThemeEditorLayout = lazy(() => import('../Modules/Dashboard/User/ThemeEditor/ThemeEditor'));
const RoleListLayout = lazy(() => import('../Modules/Dashboard/User/RoleManagement/RoleList'));
const AddRoleLayout = lazy(() => import('../Modules/Dashboard/User/RoleManagement/AddRole/AddRole'));
const MemberListLayout = lazy(() => import('../Modules/Dashboard/Admin/MemberList/MemberList'));
const AddMemberLayout = lazy(() => import('../Modules/Dashboard/Admin/MemberList/AddMember/AddMember'));
const ChangePlanLayout = lazy(() => import('../Modules/Dashboard/User/ChangePlan/ChangePlan'));
const AddWidgetLayout = lazy(() => import('../Modules/Dashboard/User/WidgetManagement/AddWidget/AddWidget'));
const WidgetListLayout = lazy(() => import('../Modules/Dashboard/User/WidgetManagement/WidgetList/WidgetList'));
const DeactivateAccountLayout = lazy(() => import('../Modules/Dashboard/User/Deactivate/Deactivate'));
const PaymentSucessLayout = lazy(() => import('../Modules/Dashboard/User/PaymentSuccess/PaymentSuccess'));
const PaymentFailedLayout = lazy(() => import('../Modules/Dashboard/User/PaymentFailed/PaymentFailed'));
const ChatAutomationLayout = lazy(() => import('../Modules/Dashboard/User/ChatAutomation/ChatAutomation'));

const DiscountListLayout = lazy(() => import('../Modules/Dashboard/Admin/DiscountList/DiscountList'));
const AddDiscountLayout = lazy(() => import('../Modules/Dashboard/Admin/DiscountList/AddDiscount/AddDiscount'));
const OverviewLayout = lazy(() => import('../Modules/Dashboard/User/Overview/Overview'));

const AddBlogLayout = lazy(() => import('../Modules/Dashboard/Admin/BlogList/AddBlog/AddBlog'));
const BlogListLayout = lazy(() => import('../Modules/Dashboard/Admin/BlogList/BlogList'));
const EnquiryListLayout = lazy(() => import('../Modules/Dashboard/Admin/EnquiryList/EnquiryList'));

const LandingPageLayout = lazy(() => import('../Modules/LandingPage/LandingPage'));

export const routes = ({ actions, showSuccess }) => (
    [
        {
            path: '/',
            element: (<Outlet />),
            children: [
                {
                    index: true,
                    element: <Navigate to="/auth/login" />
                },
                {
                    path: 'signup',
                    element: <LandingPageLayout />
                }

            ]
        },
        {
            path: '/auth',
            element: (<Outlet />),
            children: [
                {
                    index: true,
                    element: <Navigate to="/auth/login" />
                },
                {
                    path: 'login',
                    element: <LoginLayout />
                },
                {
                    path: 'signup',
                    element: <LandingPageLayout />
                }
            ]
        },
        {
            path: '/app',
            element: (<PrivateRoute showSuccess={showSuccess} actionName={'loggedIn'} actions={actions} component={DashboardLayout} />),
            children: [
                {
                    index: true,
                    element: actions?.includes('App Settings') ? <Navigate to="/app/overview" /> : actions?.includes('User Management') ? <Navigate to="/app/users" /> : <Navigate to="/app/chat" />
                },
                {
                    path: 'users',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={'User Management'} actions={actions} component={UsersListLayout} />)
                },
                {
                    path: 'subscriptions',
                    element: (<SubscriptionsLayout />)
                },
                {
                    path: 'packages',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={'Package Management'} actions={actions} component={PackageListLayout} />)
                },
                {
                    path: 'add-package',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={'Package Management'} actions={actions} component={AddPackageLayout} />)
                },
                {
                    path: 'subscriptions',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={'Subscription Management'} actions={actions} component={SubscriptionsLayout} />)
                },
                {
                    path: 'settings',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={'App Settings'} actions={actions} component={SettingsLayout} />)
                },
                {
                    path: 'theme-editor',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={'Customize Widget'} actions={actions} component={ThemeEditorLayout} />)
                },
                {
                    path: 'roles',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={'Role Management'} actions={actions} component={RoleListLayout} />)
                },
                {
                    path: 'add-role',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={'Role Management'} actions={actions} component={AddRoleLayout} />)
                },
                {
                    path: 'add-widget',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={'Widget Management'} actions={actions} component={AddWidgetLayout} />)
                },
                {
                    path: 'widgets',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={'Widget Management'} actions={actions} component={WidgetListLayout} />)
                },
                {
                    path: 'members',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={'Role Management'} actions={actions} component={MemberListLayout} />)
                },
                {
                    path: 'add-member',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={'Role Management'} actions={actions} component={AddMemberLayout} />)
                },
                {
                    path: 'chat',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={'Chat Accessible'} actions={actions} component={ChatLayout} />)
                },
                {
                    path: 'change-plan',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={'Role Management'} actions={actions} component={ChangePlanLayout} />)
                },
                {
                    path: 'blogs',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={'Blog Management'} actions={actions} component={BlogListLayout} />)
                },
                {
                    path: 'enquires',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={'Enquiry Management'} actions={actions} component={EnquiryListLayout} />)
                },
                {
                    path: 'add-blog',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={'Blog Management'} actions={actions} component={AddBlogLayout} />)
                },
                {
                    path: 'payment-success',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={'App Settings'} actions={actions} component={PaymentSucessLayout} />)
                },
                {
                    path: 'payment-failed',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={'App Settings'} actions={actions} component={PaymentFailedLayout} />)
                },
                {
                    path: 'discounts',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={'Discount Management'} actions={actions} component={DiscountListLayout} />)
                },
                {
                    path: 'add-discount',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={'Discount Management'} actions={actions} component={AddDiscountLayout} />)
                },
                {
                    path: 'overview',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={'App Settings'} actions={actions} component={OverviewLayout} />)
                },
                {
                    path: 'chat-automation',
                    element: (<PrivateRoute showSuccess={showSuccess} actionName={'App Settings'} actions={actions} component={ChatAutomationLayout} />)
                },
            ]
        },
    ]
)