import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  actions: [],
  trialExpired: null,
  trailExpirationDays: null,
  hasOrder: null,
  isDeactivated: false,
  loading: false,
  hasOrder: null
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addActions(state, action) {
      state.actions = action.payload?.actions;
      state.hasOrder = action.payload.hasOrder;
      state.trailExpirationDays = action.payload?.trailExpirationDays;
      state.trialExpired = action.payload?.trialExpired;
    },
    showLoader(state) {
      state.loading = true
    },
    hideLoader(state) {
      state.loading = false;
    },
    resetState(state, action) {
      state.actions = [];
    }
  },
})

export const { addActions, resetState, showLoader, hideLoader } = userSlice.actions;

export default userSlice.reducer