import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Navigate, useNavigate } from 'react-router-dom';
import { socket } from '../App';
import TokenService from '../Services/token.service';
import UserService from '../Services/user.service';
import { addActions } from '../Store/features/user.slice';

const PrivateRoute = ({ component: Component, actionName, actions, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentUser = TokenService.getLocalAccessToken();
    console.log(currentUser, actions)
    useEffect(() => {
        if (currentUser && !actions.length) {
            userinfo()
        }
    }, [])

    const userinfo = async () => {
        try {
            const res = await UserService.getUserinfo();
            if (res?.data) {
                socket.emit('user', {id:res.data.id})
                dispatch(addActions(res?.data));
            }
            if(res?.data?.actions && res?.data?.actions.length) {
              return  res?.data?.actions.includes('User Management') ? navigate('/users') : res?.data?.actions.includes('App Settings') ?  navigate('/app/settings') : navigate('/app/chat'); 
            }

        } catch (error) {
            console.log(error)
        }
    }

    if (!currentUser) {
        TokenService.removeUser();
        return <Navigate to={{ pathname: '/auth/login', state: { from: rest.location } }} />
    }
    if (actions.length && !actions.includes(actionName)) {
        // TokenService.removeUser();
        return <Navigate to={{ pathname: '/auth/login' }} />
    } else {
        return (<Component {...rest} />)
    }


}

export default PrivateRoute;