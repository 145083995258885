import React, { createContext, useState, useRef, useEffect } from 'react';
import Peer from 'simple-peer';
import { socket } from '../App'

const SocketContext = createContext();
let connectionPeer;
let localStream;
let toId;

const ContextProvider = ({ children }) => {
    const [callAccepted, setCallAccepted] = useState(false);
    const [callEnded, setCallEnded] = useState(false);
    const [stream, setStream] = useState();
    const [name, setName] = useState('');
    const [call, setCall] = useState({});
    const [me, setMe] = useState('');
    const [showVideo, setShowVideo] = useState(false);
    const [showAudio, setShowAudio] = useState(false);

    const myVideo = useRef();
    const userVideo = useRef();

    useEffect(() => {
        socket.on('me', (id) => {
            console.log(id);
            setMe(id)
        });

        socket.on('callUser', ({ from, name: callerName, signal }) => {
            console.log('drf')
            setCall({ isReceivingCall: true, from, name: callerName, signal });
        });
    }, []);

    const answerCall = async () => {
        const getStream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
        setStream(getStream)
        localStream = getStream;
        setCallAccepted(true);

        const peer = new Peer({ initiator: false, trickle: false, stream: getStream });
        peer.on('signal', (data) => {
            console.log(data)
            socket.emit('answerCall', { signal: data, to: call.from });
            toId = call.from;
        });

        peer.on('stream', (currentStream) => {
            console.log(currentStream)
            userVideo.current.srcObject = currentStream;
        });
        myVideo.current.srcObject = getStream;

        peer.signal(call.signal);

        connectionPeer = peer;
        socket.on('callEnded', () => {
            clearOff()
        });
    };

    const callUser = async (id) => {
        const getStream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
        setStream(getStream)
        localStream = getStream;
        myVideo.current.srcObject = getStream;
        const peer = new Peer({ initiator: true, trickle: false, stream: getStream });

        peer.on('signal', (data) => {
            socket.emit('callUser', { userToCall: id, signalData: data, from: me, name });
            toId = id;
        });

        peer.on('stream', (currentStream) => {
            userVideo.current.srcObject = currentStream;
        });

        socket.on('callAccepted', (signal) => {
            setCallAccepted(true);

            peer.signal(signal);
        });

        socket.on('callEnded', () => {
            clearOff()
        });

        connectionPeer = peer;
    };

    const leaveCall = () => {
        socket.emit('callEnded', { id: toId })
        clearOff()

        // window.location.reload();
    };

    const clearOff = () => {
        stopBothVideoAndAudio();
        setCallEnded(true);
        setCallAccepted(false)
        setCall({ ...call, isReceivingCall: false })
        if (connectionPeer) {
            connectionPeer.destroy();
        }
    }

    async function stopBothVideoAndAudio() {
        console.log(localStream, localStream.getVideoTracks())
        localStream.getTracks().forEach(function (track) {
            console.log(track.stop())
            track.stop();
        });
    }

    // stop only camera
    async function stopVideoOnly() {
        console.log(showVideo)
        if (showVideo) {
            const tracks = localStream.getVideoTracks();
            console.log(tracks)
            tracks[0].stop();
        } else {
            const getStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: showAudio });
            setStream(getStream)
            localStream = getStream;
            myVideo.current.srcObject = getStream;
        }
        setShowVideo(!showVideo)
    }

    // stop only mic
    async function stopAudioOnly() {
        localStream.getVideoTracks().forEach(track => {
            track.enabled = !track.enabled;
        })
        setShowAudio(!showAudio)
    }

    return (
        <SocketContext.Provider value={{
            call,
            callAccepted,
            myVideo,
            userVideo,
            stream,
            name,
            setName,
            callEnded,
            me,
            callUser,
            leaveCall,
            answerCall,
            stopAudioOnly,
            stopVideoOnly,
            stopBothVideoAndAudio,
            showAudio,
            showVideo
        }}
        >
            {children}
        </SocketContext.Provider>
    );
};

export { ContextProvider, SocketContext };