import api from './api';

const UserService = {
    
    getSettings() {
        return api.get("/user/get-settings");
    },
    getOverview() {
        return api.get("/user/get-overview");
    },
    createSettings(data) {
        return api.post("/user/create-settings", data);
    },
    getRoles() {
        return api.get("/user/get-roles");
    },
    getRoleById(id) {
        return api.get(`/user/get-role/${id}`);
    },
    checkout(type) {
        return api.get(`/user/checkout-session/${type}`);
    },
    createRole(data) {
        return api.post("/user/create-role", data);
    },
    editRole(data) {
        return api.put("/user/edit-role", data);
    },
    markRoleAsDefualt(data) {
        return api.put("/user/mark-role-as-default", data);
    },
    createTheme(data) {
        return api.post("/user/create-theme", data);
    },
    editTheme(data) {
        return api.put("/user/edit-theme", data);
    },
    getThemeById() {
        return api.get(`/user/get-theme`);
    },
    getThemes() {
        return api.get("/user/get-themes");
    },

    createMember(data) {
        return api.post("/user/create-member", data);
    },
    editMember(data) {
        return api.put("/user/edit-member", data);
    },
    getMemberById(id) {
        return api.get(`/user/get-member/${id}`);
    },
    getMembers() {
        return api.get("/user/get-members");
    },
    getConvs() {
        return api.get(`/user/get-convs`);
    },
    getUserinfo() {
        return api.get("/user/userinfo");
    },
    getPlan() {
        return api.get("/user/get-plan");
    },
    createEnquiry(data) {
        return api.post("/user/create-enquiry", data);
    },
    createGroup(data) {
        return api.post("/user/create-group", data);
    },
    editGroup(data) {
        return api.put("/user/edit-group", data);
    },
    getMembersForChat() {
        return api.get("/user/get-members-for-chat");
    },
    createWidget(data) {
        return api.post("/user/create-widget", data);
    },
    editWidget(data) {
        return api.put("/user/edit-widget", data);
    },
    getWidgets() {
        return api.get("/user/get-widgets");
    },
    getWidgetsForMember() {
        return api.get("/user/get-widgets-for-member");
    },
    getWidgetById(id) {
        return api.get(`/user/get-widget/${id}`);
    },
    deactivateAccount() {
        return api.get(`/user/deactivate`);
    },
    editAutomation(data) {
        return api.post("/user/edit-automation", data);
    },
    getAutomation() {
        return api.get(`/user/get-automation`);
    },
}

export default UserService;