import "./App.css";
import { useRoutes } from "react-router-dom";
import { routes } from "./Routes/Routes";
import { Suspense, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chat from "./Modules/Chat.js/Chat";
import { Toast } from "primereact/toast";
import useSocket from "./Hooks/useSocket";
import CookieConsent from "react-cookie-consent";
import UserService from "./Services/user.service";
import { addActions } from "./Store/features/user.slice";
import TokenService from "./Services/token.service";
import loadingImg from './loading1.gif'

export let socket;

function App() {
  [socket] = useSocket("https://be.plugtochat.com", {
    transports: ["websocket"],
  });

  const actions = useSelector((state) => {
    console.log(state?.user?.actions);
    return state?.user?.actions || [];
  });

  const loading = useSelector((state) => state?.user?.loading)

  const toast = useRef(null);

  const showSuccess = (value) => {
    toast.current.show({
      severity: "success",
      summary: value.detail,
      detail: value.message,
    });
  };

  const renderLoader = () => (<div style={{
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    backgroundColor: '#fff',
    zIndex: 99999
  }}>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <img src={loadingImg} className="img-fluid" />
      <h4 style={{ textAlign: 'center', fontSize: 30 }}>Loading resources</h4>
    </div>
  </div>)

  return (
    <>
      <CookieConsent
        buttonText="Accept"
        cookieName="cookieConsent"
        style={{
          background: "linear-gradient(to right, #2958E5, 60%,#04B9FB)",
          justifyContent: "center",
        }}
        buttonStyle={{
          background: "#ffff",
          color: "#2958E5",
          fontSize: "18px",
          borderRadius: "32px",
          height: "40px",
          width: "120px",
          marginTop: "0px",
          fontWeight: "bold",
        }}
        expires={3999}
        contentStyle={{ flex: "0 0 100%", textAlign: "center" }}
        overlay
      >
        <span style={{ fontSize: "20px" }}>
          We uses cookies in order to make your experience better on this site.
        </span>
      </CookieConsent>
      <Toast ref={toast} />
      {/* {
        loading && (
          renderLoader()
        )
      } */}
      <Suspense fallback={() => renderLoader()}>
        {useRoutes(routes({ actions, showSuccess }))}
      </Suspense>
    </>
  );
}

export default App;
